import React from 'react';
import {Link} from 'react-router-dom';

function Breadcrumbs(props) {
    return (
        <section className="breadcrumb-area banner-1" data-background="images/banner/9.jpg">
        <div className="text-block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 v-center">
                <div className="bread-inner">
                  <div className="bread-menu">
                    <ul>
                      <li><Link to="/" >Home</Link></li>
                      <li><Link to="/blog" >Blog</Link></li>
                    </ul>
                  </div>
                  <div className="bread-title">
                    <h2>Blog</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default Breadcrumbs;