import React from 'react';
import image1 from '../../images/ecom.png'
import image2 from '../../images/wordpress.png'
import image3 from '../../images/ui.png'
import image4 from '../../images/creativeapp.jpg';
import {Fade} from 'react-reveal';

function Portfolio(props) {
    return (
        <section className="portfolio-section pad-tb">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Our Work</span>
                <h2 className="mb0">Our Latest Creative Work</h2>
              </div>
            </div>
          </div>
          <div className="row">

            <div className="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.2s">
            <Fade bottom delay={400} duration={1000} >
              <div className="isotope_item hover-scale">
                <div className="item-image" data-tilt data-tilt-max={2} data-tilt-speed={1000}>
                  <a href="https://portunion.townwings.ca/"><img src={image1} alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info">
                  <h4><a href="https://portunion.townwings.ca/">Ecommerce Development</a></h4>
                  <p>Single Vendor and Multi Vendor E-Commerce</p>
                </div>
              </div>
              </Fade>
            </div>


            <div className="col-lg-4 col-sm-4 mt60 wow fadeInUp" data-wow-delay="0.4s">
            <Fade bottom delay={600} duration={1000} >
              <div className="isotope_item hover-scale">
                <div className="item-image" data-tilt data-tilt-max={2} data-tilt-speed={1000}>
                  <a href="#"><img src={image4} alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info">
                  <h4><a href="#">Creative App</a></h4>
                  <p>iOs, Android</p>
                </div>
              </div>
              </Fade>
            </div>





            <div className="col-lg-4 col-sm-4 mt60 wow fadeInUp">
            <Fade bottom delay={800} duration={1000} >
              <div className="isotope_item hover-scale">
                <div className="item-image" data-tilt data-tilt-max={2} data-tilt-speed={1000}>
                  <a href="#"><img src={image2} alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info">
                  <h4><a href="#">Web Design & Development</a></h4>
                  <p>React Js, Angular Js, WordPress, PHP, Python</p>
                </div>
              </div>
              </Fade>
            </div>



            <div className="col-lg-8 col-sm-8 mt60 wow fadeInUp" data-wow-delay="0.8s">
            <Fade bottom delay={1000} duration={1000} >
              <div className="isotope_item hover-scale">
                <div className="item-image" data-tilt data-tilt-max={2} data-tilt-speed={1000}>
                  <a href="#"><img src={image3} alt="image" className="img-fluid" /> </a>
                </div>
                <div className="item-info">
                  <h4><a href="#">UI / UX Development</a></h4>
                  <p>Web and Mobile App</p>
                </div>
              </div>
              </Fade>
            </div>



          </div>
        </div>
      </section>
      
    );
}

export default Portfolio;