import React from 'react';
import office1 from '../../images/office1.png';
import office2 from '../../images/office2.png';
import office3 from '../../images/office3.jpg';
import {Fade} from 'react-reveal';



function Location(props) {
    return (
        <section className="our-office pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Our Locations</span>
                <h2>Our Office</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center upset shape-numm">


            <div className="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".2s">
            <Fade bottom duration={900} delay={200} >
              <div className="office-card">
                <div className="skyline-img" data-tilt data-tilt-max={4} data-tilt-speed={1000}>
                  <img src={office1} alt="India" className="img-fluid" />
                </div>
                <div className="office-text">
                  <h4>Canada</h4>
                  <p>107 Eastvale Dr, Markham, ON L3S 4T4, Canada</p>
                  <a href="https://goo.gl/maps/EBg754NL75HGWJLa7" target="blank" className="btn-outline rount-btn"><i className="fas fa-map-marker-alt" /></a>
                  <a href="tel:+16473257275" target="blank" className="btn-outline rount-btn"><i className="fas fa-phone-alt" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-envelope" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fab fa-skype" /></a>
                </div>
              </div>
              </Fade>
            </div>




            {/* <div className="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".5s">
            <Fade bottom duration={900} delay={400} >
              <div className="office-card">
                <div className="skyline-img" data-tilt data-tilt-max={4} data-tilt-speed={1000}>
                  <img src={office2} alt="India" className="img-fluid" />
                </div>
                <div className="office-text">
                  <h4>India</h4>
                  <p>#1050/54M, Thiruvottiyur High Road, Chennai.</p>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-map-marker-alt" /></a>
                  <a href="tel:+918248742682" target="blank" className="btn-outline rount-btn"><i className="fas fa-phone-alt" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-envelope" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fab fa-skype" /></a>
                </div>
              </div>
              </Fade>
            </div> */}



            {/* <div className="col-lg-4 col-sm-6 shape-loc wow fadeInUp" data-wow-delay=".8s">
             <Fade bottom duration={900} delay={600} >
              <div className="office-card mb0">
                <div className="skyline-img" data-tilt data-tilt-max={4} data-tilt-speed={1000}>
                  <img src={office3} alt="Malaysia" className="img-fluid" />
                </div>
                <div className="office-text">
                  <h4>Malaysia</h4>
                  <p>Little India, Kuala Lumpur, Malaysia</p>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-map-marker-alt" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-phone-alt" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fas fa-envelope" /></a>
                  <a href="javascript:void(0)" target="blank" className="btn-outline rount-btn"><i className="fab fa-skype" /></a>
                </div>
              </div>
              </Fade>
            </div> */}

          </div>
        </div>
      </section>
      
    );
}

export default Location;