import React from 'react';
import cyvo from '../../images/cyvo.jpg'
import vastrissilks from '../../images/vastrissilks.png';
import triprint from '../../images/triprint.png';
import townwings from '../../images/townwings.png';
import dbc from '../../images/dbc.png';



const Card = (props)=> (

    <div className="col-lg-3 col-sm-3 mt40 single-card-item website">
        <a href={props.url} >
        <div className="isotope_item up-hor">
          <div className="item-image">
        <img src={props.img} alt="image" className="img-fluid" /> 
          </div>
          <div className="item-info-div shdo">
            <h4>{props.title}</h4>
           
          </div>
        </div>
        </a>
      </div>
)



function Portfoliocard(props) {
    return (
        <section className="portfolio-page pad-tb">
  <div className="container">
    <div className="row justify-content-left">
      <div className="col-lg-6">
        <div className="common-heading pp">
          <span>Our Work</span>
          <h2>Portfolio</h2>
        </div>
      </div>
    
    </div>
    <div className="row card-list">
    
      
        <Card img={cyvo} title="CYVO" url="https://cyvo.org/" />
        <Card img={vastrissilks} title="Vastris Silks" url="https://www.vastrissilks.com/" />
        <Card img={triprint} title="Triprint" url="https://triprint.ink/" />
        <Card img={townwings} title="Townwings" url="http://portunion.townwings.ca/" />
        <Card img={dbc} title="Digital Business Card" url="https://www.digitalbizcard.ca/" />

        



    </div>
  </div>
</section>

    );
}

export default Portfoliocard;