import React , {useState} from 'react';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import about from '../../images/about.png';


const Counter = ({ className, ...rest })=> {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return(
    <CountUp {...rest} className="counter" duration={5} start={viewPortEntered ? null : 0}>
    {({ countUpRef }) => {
      return (
        <VisibilitySensor
          active={!viewPortEntered}
          onChange={isVisible => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          }}
          delayedCall
        >
          <h4 className={className} ref={countUpRef} />
        </VisibilitySensor>
      );
    }}
  </CountUp>
  )
}







function About(props) {
    return (
        <section className="about-agency pad-tb block-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 v-center">
              <div className="about-image">
                <img src={about} alt="about us" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="common-heading text-l ">
                <span>About Us</span>
                <h2>About Sunsas</h2>
                <p>Sunsas is one of the fastest growing IT company in Canada. We have successfully delivered 60+ Projects across the worlds, We provide our customers with really affordable web Design plans at the highest quality possible. How We Commenced Sunsas began its operations by offering world-class Web design and App Design services to its round the globe clientele. </p>
               
              </div>
              <div className="row in-stats small about-statistics">
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics">
                    <div className="statnumb counter-number">
                      <Counter end={50} />
                      <p>Happy Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics">
                    <div className="statnumb">
                    <Counter end={95} suffix="K"  />
                      <p>Hours Worked</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="statistics mb0">
                    <div className="statnumb counter-number">
                    <Counter end={60} />
                      <p>Projects Done</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default About;