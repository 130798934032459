import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import {Digitalmarketing} from './ourservice';
import Process from './Process';
import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <Tech />
            <Process />
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;