import React, {useEffect} from 'react';
import Header from '../header/header';
import Footer from '../Footer/footer';
import Breadcrumb from './breadcrumbs';
import ContactForm from './contactForm';
import Location from '../home/Location';

function Contactindex(props) {
    
    useEffect(()=>{
        window.scroll(0,0);
    },[])


    return (
        <div>
            <Header />
            <Breadcrumb />
            <ContactForm />
            <Location />
            <Footer />
        </div>
    );
}

export default Contactindex;