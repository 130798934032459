import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumbs';
import About from './about';
import WhyChoose from './whychooseus';
import Footer from '../Footer/footer';


function Aboutindex(props) {

    useEffect(()=>{
        window.scroll(0,0);
    },[])

    return (
        <div>
            <Header color="w" />
            <BreadCrumbs />
            <About />
            <WhyChoose />
            <Footer />
        </div>
    );
}

export default Aboutindex;