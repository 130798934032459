import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/style.css';
import './css/responsive.css';




ReactDOM.render(
  
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
  
);


