import React from 'react';
import development from '../../images/icons/development.svg';
import ecommerce from '../../images/icons/ecommerce.svg';
import app from '../../images/icons/app.svg';
import tech from '../../images/icons/tech.svg';
import seo from '../../images/icons/seo.svg';
import {Fade} from 'react-reveal';
import {Link} from 'react-router-dom';


function Service(props) {
    return (
        <section className="service-section-prb pad-tb">
        <div className="container">
          <div className="row upset">
            <div className="col-lg-6-cus " >
           <Fade bottom duration={1000} >
              <div className="service-sec-brp srvc-bg-nx bg-gradient13 text-w">
                <h4 className="mb10">INTEGRATED SERVICES</h4>
                <p>Ecommerce website design and development. Website integration with back-office processes and in-store operations. Customer experience personalization.</p>
                <a href="javascript:void(0)" className="mt20 link-prb">Learn More <i className="fas fa-chevron-right" /></a>
              </div>
              </Fade>
            </div>

            <Fade bottom duration={1000} delay={800} >
            <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="col-lg-3-cus wow fadeInUp" data-wow-delay=".4s">
              <div className="service-sec-list srvc-bg-nx srcl1">
                <img src={development} alt="service" />
                <h5 className="mb10">Web Development</h5>
                <ul className="-service-list">
                  <li> <a href="http://sunsas.ca/consultationservice">MERN STACK</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">MEAN STACK</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">DJANGO</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">PYTHON</a></li>
                </ul>
                <p>Make your website attractive and load the necessary tools that provide the unbeatable user experience. We have the best-in-class platforms and tools to build a fabulous business website for your business.</p>
              </div>
            </div>
            </Fade>



            
            <Fade bottom duration={1000} delay={1200} >
            <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="col-lg-3-cus wow fadeInUp" data-wow-delay=".6s">
              <div className="service-sec-list srvc-bg-nx srcl2">
                <img src={ecommerce}alt="service" />
                <h5 className="mb10">Ecommerce Development</h5>
                <ul className="-service-list">
                  <li> <a href="http://sunsas.ca/consultationservice">SINGLE VENDOR</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">WP</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">Shopify </a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">Joomla</a></li>
                </ul>
                <p>Setting up an e-commerce store may involve a lot of technical and commercial factors. We help build profitable e-commerce stores that are attractive, optimized for ranking and easy-to-navigate.</p>
              </div>
            </div>
            </Fade>



            <Fade bottom duration={1000} delay={1400} >
            <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay=".8s">
              <div className="service-sec-list srvc-bg-nx srcl3">
                <img src={app} alt="service" />
                <h5 className="mb10">Mobile App Development</h5>
                <ul className="-service-list">
                  <li> <a href="http://sunsas.ca/consultationservice">REACT NATIVE</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">FLUTTER</a> </li>
                  <li> <a href="http://sunsas.ca/consultationservice">JAVA</a></li>
                  <li> <a href="http://sunsas.ca/consultationservice">SWIFT</a></li>
                </ul>
                <p>Even a million-dollar idea need a user-friendly app to make it huge. Our developers have the brightest minds and fastest fingers that can make your idea go live.</p>
              </div>
            </div>
            </Fade>



            <Fade bottom duration={1000} delay={1600} >
            <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="col-lg-3-cus mt30- wow fadeInUp" data-wow-delay="1s">
              <div className="service-sec-list srvc-bg-nx srcl4">
                <img src={tech} alt="service" />
                <h5 className="mb10">Branding Consultation</h5>
                <ul className="-service-list">
                  <li> <a href="http://sunsas.ca/DigitalMarketing">LOGO </a> </li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">BROCHURE </a> </li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">PAMPHLETS</a></li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">POSTERS</a></li>
                </ul>
                <p>Brilliant branding campaigns create the foundation for an everlasting business. Our successful branding strategies can help spread your brand in the world of competition.</p>
              </div>
            </div>
            </Fade>


            <Fade bottom duration={1000} delay={1800} >
            <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="col-lg-6-cus mt30- wow fadeInUp" data-wow-delay="1.2s">
              <div className="service-sec-list srvc-bg-nx srcl5">
                <img src={seo} alt="service" />
                <h5 className="mb10">Digital Marketing</h5>
                <ul className="-service-list">
                  <li> <a href="http://sunsas.ca/DigitalMarketing">SEO </a> </li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">SEM </a> </li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">SMM </a></li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">SMO </a></li>
                  <li> <a href="http://sunsas.ca/DigitalMarketing">PPC </a></li>
                </ul>
                <p>Today’s market is full of digitally proficient buyers. Reach your target demography digitally with the right marketing techniques. Get help from our digital marketing consultants.</p>
              </div>
            </div>
          </Fade>

          </div>

          {/* <Fade top duration={1000} delay={600} >
          <div className="-cta-btn mt70">
            <div className="free-cta-title v-center zoomInDown wow" data-wow-delay="1.4s">
              <p>Hire a <span>Dedicated Developer</span></p>
              <Link to="/contact" className="btn-main bg-btn2 lnk">Hire Now<i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
            </div>
          </div>
          </Fade> */}


        </div>
      </section>
      
    );
}

export default Service;