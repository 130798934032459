import React from 'react';
import image1 from '../../images/process1.jpg';
import image2 from '../../images/process2.jpg';
import image3 from '../../images/process3.jpg';
import image4 from '../../images/process4.jpg';


function Process(props) {
    return (
        <section className="service-block pad-tb light-dark">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading ptag">
                <span>Process</span>
                <h2>Our App Development Process</h2>
                <p>
                Our App development process involves four steps, and we never deviate from the process. Our method aims at making the overall project more straightforward and ensuring the best results. Our app development process helped us earn many returning customers. Read on to explore our app development process.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src={image1} alt="Process" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>1</span>
                <h3>Connecting the dots</h3>
                <p>
                The first step of the app development process involves detailed discussions and the exchange of information. In this step, we prepare a blueprint that enables the developers to understand the overall project requirement. When the developers understand the purpose of the app, the results tend to be perfect. Finally, we come up with the project plan after multiple revisions and consultations.
                </p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>2</span>
                <h3>Prototype</h3>
                <p>
                Since our developers work on a pre-defined project plan, the prototype will align with the actual requirement. However, no project will reach completion without last-minute changes, additions, and enhancements. Therefore, you can take your time inspecting the prototype and let us know if you want to add or revise any features in the application.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src={image2} alt="Process" className="img-fluid" />
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src={image3} alt="Process" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>3</span>
                <h3>App integration</h3>
                <p>Deploying the application involves evaluating and rectifying features that establish a flawless connection to the back-end systems. In addition, our developers will assist you in securing the endpoints as well. After successful integration, your application will be ready for use by all stakeholders.</p>
              </div>
            </div>
          </div>
          <div className="row upset justify-content-center mt60">
            <div className="col-lg-7 v-center order2">
              <div className="ps-block">
                <span>4</span>
                <h3>Support &amp; Maintenance</h3>
                <p>
                App integration is not the end, but it’s a great beginning of a new success story. However, your application might need maintenance & tech support for many reasons. In the future, you may want to add or remove any features, scale up the bandwidth, improve the user interface, or improve efficiency. In any case, our support & maintenance service will back you up.
                </p>
              </div>
            </div>
            <div className="col-lg-4 v-center order1">
              <div className="image-block1">
                <img src={image4} alt="Process" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default Process;