import React, { useEffect } from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import { CloudHostingService } from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}

            {/* <Client /> */}
            <About
                title={"Cloud Hosting Services"}
                about1={'Achieving business growth can be fabulous, but shared web hosting services may not be enough for your website when your website has to handle massive traffic and extensive data. Sunsas.INC offers the best Cloud hosting services at competitive pricing. '}
                about2={""}
                about3={"Click here to know more."} />
            <Tech />
            <CloudHostingService />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;