import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Home from './components/home/homeindex';
import About from './components/AboutUs/aboutindex';
import consultationservice from './components/Services/consultationservice';
import DigitalMarketing from './components/Services/digitalmarketing';
import ContentWriting from './components/Services/content_writing';
import WebHostingService from './components/Services/webHostingService';
import WordpressWebHostingService from './components/Services/wordpressWebHosting';
import wooCommerceHosting from './components/Services/wooCommerceHosting';
import CloudHostingService from './components/Services/CloudHostingService';
import OurAppProcess from './components/Services/OurAppProcess';
import Socialmedia from './components/Services/socialmedia';
import Blog from './components/Blog/Blogindex';
import Contact from './components/Contactus/contactindex';
import Portfolio from './components/Portfolio/portfolioIndex';
import ReactGA from 'react-ga';
ReactGA.initialize('G-W9CP7VCXJ3');


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/consultationservice" component={consultationservice} />
        <Route exact path="/DigitalMarketing" component={DigitalMarketing} />
        <Route exact path="/Socialmedia" component={Socialmedia} />
        <Route exact path="/OurAppProcess" component={OurAppProcess} />
        <Route exact path="/ContentWriting" component={ContentWriting} />
        <Route exact path="/WebHostingService" component={WebHostingService} />
        <Route exact path="/WordpressWebHostingService" component={WordpressWebHostingService} />
        <Route exact path="/wooCommerceHosting" component={wooCommerceHosting} />
        <Route exact path="/CloudHostingService" component={CloudHostingService} />
        <Route exact path="/portfolio" component={Portfolio} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/contact" component={Contact} />
      </BrowserRouter>
    </div>
  );
}

export default App;
