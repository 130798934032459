import React, {useEffect} from 'react';
import Header from '../header/header';
import TopSection from './TopSection';
import About from './About';
import Service from './Service';
import Satisfaction from './satisfaction';
import WhyChooseUs from './WhyChooseUs';
import Portfolio from './Portfolio';
import Clients from './Clients';
import Badges from './Badges';
import Category from './Category';
// import Testimonial from './Testimonial';
import CTA from './CTA';
import Location from './Location';
import Footer from '../Footer/footer';


function Homeindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    
    return (
        <div>
            <Header color="b" />
            <TopSection />
            <About />
            <Service />
            {/* <Satisfaction /> */}
            <WhyChooseUs />
            <Portfolio />
            <Clients />
            {/* <Badges /> */}
            <Category />
            {/* <Testimonial /> */}
            <CTA />
            <Location />
            <Footer />
        </div>
    );
}

export default Homeindex;