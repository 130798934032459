import React ,{useRef}  from 'react';
import emailjs from '@emailjs/browser';
import swal from 'sweetalert';
import {useHistory} from "react-router-dom";

function ContactForm(props) {

  const form = useRef();
  const history = useHistory();

  const AddSectionHandler = (e) => {
    e.preventDefault()
    emailjs.sendForm('service_jswp19j', 'template_7ufs80n',form.current ,  'O7eov2CHGCoFpcWHB')
        .then((result) => {
          console.log(result.text);
          // swal("Success","Email Send Successfully!");
          swal({
            title: "Done!",
            text: "Email Send Successfully!",
            icon: "success",
            button: false
          })
          history.goBack();

        }, (error) => {
          console.log(error.text);
          swal({
            title: "Error!",
            // text: "Email Not Send",
            icon: "error",
            button: false
          })
          history.goBack();
        });

  }

    return (
        <section className="contact-page pad-tb">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-lg-5 contact2dv">
        <div className="info-wrapr">
          <h3 className="mb-4">Contact us</h3>
          <div className="dbox d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <i className="fas fa-map-marker" />
            </div>
            <div className="text pl-4">
              <p><span>Address:</span>107 Eastvale Dr, Markham, ON L3S 4T4, Canada</p>
            </div>
          </div>
          <div className="dbox d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <i className="fas fa-phone-alt" />
            </div>
            <div className="text pl-4">
              <p><span>Phone:</span> <a href="tel:+16473257275">+1 647 325-7275</a></p>
            </div>
          </div>
          <div className="dbox d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <i className="fas fa-envelope" />
            </div>
            <div className="text pl-4">
              <p><span>Email:</span> <a href="mailto:info@sunsas.com">info@sunsas.ca</a></p>
            </div>
          </div>
          <div className="dbox d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <i className="fa fa-globe" />
            </div>
            <div className="text pl-4">
              <p><span>Website</span> <a href="#">www.sunsas.ca</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-7 m-mt30 pr30 pl30">
        <div className="common-heading text-l">							
          <h2 className="mt0 mb0">Get in touch</h2>
          <p className="mb60 mt10">We will catch you as early as we receive the message</p>
        </div>
        <div className="form-block">
          <form ref={form} id="contactForm" data-toggle="validator" className="shake" onSubmit={AddSectionHandler}>
            <div className="row">
              <div className="form-group col-sm-6">
                <input type="text" name="name" id="name" placeholder="Enter name" required data-error="Please fill Out"/>
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-sm-6">
                <input type="email" name="email" id="email" placeholder="Enter email"  required />
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <input name="mobile" type="text" id="mobile" placeholder="Enter mobile" required data-error="Please fill Out" />
                <div className="help-block with-errors" />
              </div>
              <div className="form-group col-sm-6">
                <select name="requirement" id="Dtype"  required>
                  <option value>Select Requirement</option>
                  <option value="Web Development">Web Development</option>
                  <option value="App Development">App Development</option>
                  <option value="Digital Marketing">Digital Marketing</option>
                  <option value="Cloud Solutions">Cloud Solutions</option>
                  <option value="Creatives">Creatives</option>
                </select>
                <div className="help-block with-errors" />
              </div>
            </div>
            <div className="form-group">
              <textarea name="message" id="message" rows={5} placeholder="Enter your message" defaultValue={""}  />
              <div className="help-block with-errors" />
            </div>
            <div className="custom-control custom-checkbox">
              <input type="checkbox" className="custom-control-input" id="customCheck" name="example1" defaultChecked="checked" />
              <label className="custom-control-label" htmlFor="customCheck">I agree to the <a href="javascript:void(0)">Terms &amp; Conditions</a> of Business Name.</label>
            </div>
            <button type="submit" id="form-submit" className="btn lnk btn-main bg-btn"> <span className="circle" />Submit</button>
            <div id="msgSubmit" className="h3 text-center hidden" />
            <div className="clearfix" />
            <p className="trm"><i className="fas fa-lock" />We hate spam, and we respect your privacy.</p>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

    );
}

export default ContactForm;