import React from 'react';
import shape1 from '../../images/shape/shape-3.svg';
import shape2 from '../../images/shape/shape-4.svg';
import shape3 from '../../images/shape/shape-13.svg';
import shape4 from '../../images/shape/shape-11.svg';
import {Link} from 'react-router-dom';


function CTA(props) {
    return (
        <section className="cta-area pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="common-heading">
                <span>Let's work together</span>
                <h2>We Love to Listen to Your Requirements</h2>
                <Link to="/contact" className="btn-outline">Estimate Project <i className="fas fa-chevron-right fa-icon" /></Link>
                <p className="cta-call">Or call us now <a href="tel:+16473257275"><i className="fas fa-phone-alt" /> (+1) 647 325-7275</a></p>
              </div>
            </div>
          </div>
        </div>
        <div className="shape shape-a1"><img src={shape1} alt="shape" /></div>
        <div className="shape shape-a2"><img src={shape2} alt="shape" /></div>
        <div className="shape shape-a3"><img src={shape3} alt="shape" /></div>
        <div className="shape shape-a4"><img src={shape4} alt="shape" /></div>
      </section>
      
    );
}

export default CTA;