import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import {Consultationservice} from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About 
            title={"Consultation Services"}
            about1={'SUNSAS Inc. offers the most professional and result-oriented consultation services for businesses to thrive in the ever-growing and competitive digital world. A majority of companies achieve multifold revenue growth by developing their online reach using professional services. So let’s ensure your brand achieves heights and grabs the front seat in the market.'} 
            about2={"Branding is the magical essence of a multi-million dollar business. We help tackle your competition by enhancing your brand’s online presence with our web designing and web development services. Besides, our experts will help you plan the best branding campaigns to spread your name all over Canada and worldwide."}
            about3={"Click here to consult with us about your business."} />
            <Tech />
            <Consultationservice />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;