import React from "react";
import icon1 from "../../images/icons/logo-and-branding.svg";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";

import cicon1 from '../../images/services/webdes.png';
import cicon2 from '../../images/services/webdev.png';
import cicon3 from '../../images/services/brand.png';
import cicon4 from '../../images/services/digital.png';
import cicon5 from '../../images/services/appdev.png';
import cicon6 from '../../images/services/buisness.png';
import cicon7 from '../../images/services/ecommerce.png';
import cicon8 from '../../images/services/IT.png';


import dicon1 from '../../images/services/SEM.png';
import dicon2 from '../../images/services/SMM.png';
import dicon3 from '../../images/services/SEO.png';
import dicon4 from '../../images/services/SMO.png';
import dicon5 from '../../images/services/SEA.png';
import dicon6 from '../../images/services/SMA.jpg';
import dicon7 from '../../images/services/emailmarketing.jpg';
import dicon8 from '../../images/services/ORM.png';

import sicon1 from '../../images/services/SMS.png';
import sicon2 from '../../images/services/SMCM.png';
import sicon3 from '../../images/services/MAA.png';
import sicon4 from '../../images/services/fb.png';
import sicon5 from '../../images/services/insta.jpg';
import sicon6 from '../../images/services/linkedin.png';
import sicon7 from '../../images/services/googleads.png';
import sicon8 from '../../images/services/snapchat.png';
import sicon9 from '../../images/services/twitter.png';
import sicon10 from '../../images/services/ms.png';

import c1 from '../../images/c1.png';
import c2 from '../../images/c2.png';
import c3 from '../../images/c3.jpg';
import c4 from '../../images/c4.png';
import c5 from '../../images/c5.png';
import c6 from '../../images/c6.png';
import c7 from '../../images/c7.png';
import c8 from '../../images/c8.jpg';


import web1 from '../../images/webhostingservice/w1.jpg';
import web2 from '../../images/webhostingservice/web2.png';
import web3 from '../../images/webhostingservice/web3.png';
import web4 from '../../images/webhostingservice/web4.jpg';

import word1 from '../../images/wordpressservice/word1.png';
import word2 from '../../images/wordpressservice/word2.png';
import word3 from '../../images/wordpressservice/word3.jpg';
import word4 from '../../images/wordpressservice/word4.png';


import woo1 from '../../images/woocommerceservice/woo1.png';
import woo2 from '../../images/woocommerceservice/woo2.png';
import woo3 from '../../images/woocommerceservice/woo3.jpg';
import woo4 from '../../images/woocommerceservice/woo4.png';
import woo5 from '../../images/woocommerceservice/woo5.jpg';
import woo6 from '../../images/woocommerceservice/woo6.png';

import cloud1 from '../../images/cloudservices/cloud1.png';
import cloud2 from '../../images/cloudservices/cloud2.png';
import cloud3 from '../../images/cloudservices/cloud3.png';
import cloud4 from '../../images/cloudservices/cloud4.jpg';


const Card = (props) => (
  <div className="col-lg-4 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
    <Fade bottom delay={props.delay} duration={1000}>
      <div className="s-block">
        <div className="s-card-icon">
          <img src={props.img} alt="service" className="img-fluid anim-img-services" width={props.w || "150px"} />
        </div>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </Fade>
  </div>
);


export function Consultationservice(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={cicon1}
            title="Web Design Consultation"
            description={` Your website design should speak the right ideology and language. A website’s outlook is the fishhook that catches the visitor’s attention. Our team can show you the “touch” that works best for your business.`}
            delay={400}
          />
          <Card
            img={cicon2}
            title="Web Development Consultation"
            description={` Make your website attractive and load the necessary tools that provide the unbeatable user experience. We have the best-in-class platforms and tools to build a fabulous business website for your business.`}
            delay={600}
          />
          <Card
            img={cicon3}
            title="Branding Consultation"
            description={`Brilliant branding campaigns create the foundation for an everlasting business. Our successful branding strategies can help spread your brand in the world of competition.`}
            delay={800}
          />
          <Card
            img={cicon4}
            title="Digital Marketing Consultation"
            description={` Today’s market is full of digitally proficient buyers. Reach your target demography digitally with the right marketing techniques. Get help from our digital marketing consultants.`}
            delay={1000}
          />
          <Card
            img={cicon5}
            title="App Development Consultation"
            description={` Even a million-dollar idea need a user-friendly app to make it huge. Our developers have the brightest minds and fastest fingers that can make your idea go live.`}
            delay={1200}
          />
          <Card
            img={cicon6}
            title="Business Phone Consultation – VOIP"
            description={` Expenses for business phones might eat away most of your profits. We help you with the connections that can bring down the cost of phones while not compromising on the technology essential for your business.`}
            delay={1400}
            width="150px"
          />
          <Card
            img={cicon7}
            title="Online store consultation – E-commerce"
            description={` Setting up an e-commerce store may involve a lot of technical and commercial factors. We help build profitable e-commerce stores that are attractive, optimized for ranking and easy-to-navigate.`}
            delay={1600}
            width={"150px"}
          />
          <Card
            img={cicon8}
            title="IT Consultation Services"
            description={` IT infrastructure and IT management is the backbone of digital businesses. We help manage many of our clients’ companies with fabulous IT setup. Get help from our IT consultation specialists to make yours a grand success.`}
            delay={1800}
          />
        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}






export function Digitalmarketing(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={dicon1}
            title="SEM - Search Engine Marketing"
            description={` Search engine marketing can fetch you higher revenues by bringing in prospective buyers while elevating your brand visibility. Get our expert consultation to know more.`}
            delay={400}
          />
          <Card
            img={dicon2}
            title="SMM - Social Media Marketing"
            description={`Uncover the most-effective social media promotions that bring in sales, traffic, brand awareness and leads altogether. Talk to our social media experts to explore more about it.`}
            delay={600}
          />
          <Card
            img={dicon3}
            title="SEO – Search engine optimization"
            description={`Always stay on the top search results to get massive traffic and higher sales. SEO optimization help get organic traffic and long-lasting ranking. Our SEO team ensure your clients find you easily online.`}
            delay={800}
          />
          <Card
            img={dicon4}
            title="SMO – Social Media Optimization"
            description={` Social media accounts are the gateways that enable your customers to reach you directly. Get famous while boosting brand awareness through our data-driven strategies.`}
            delay={1000}
          />
          <Card
            img={dicon5}
            title="SEA – Search Engine Advertising"
            description={`
            Search engine advertisements and click-ads can bring-in potential buyers to your website. Our team can help find the optimal advertising strategy to conserve your spending and increase sales.
            `}
            delay={1200}
          />
          <Card
            img={dicon6}
            title="SMA – Social Media Advertising"
            description={`Social media advertising has more advantages than search engine advertising. Find the right display networks and communities that can double up your business in a blink. Consult with our experts today to know more.`}
            delay={1400}
          />
          <Card
            img={dicon7}
            title="E-mail Marketing"
            description={`E-mail marketing is a great way to reach high-net-worth individuals and professionals. Our experts can help construct compelling E-mails and carryout effective E-mail marketing campaigns.`}
            delay={1600}
          />
          <Card
            img={dicon8}
            title="ORM – Online reputation management"
            description={`Influencers, reviewers and even a random person could damage your reputation with a few tweets and posts. We help you do excellent online reputation management that can save your brand’s goodwill in the market.`}
            delay={1800}
          />
        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}




export function Socialmedia(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={sicon1}
            title="Social media setup"
            description={`Create an attractive profile for your business that looks catchy and classy. We have an expert team who will set up your accounts on Facebook, Linkedin, Twitter, and Youtube as per your business needs,`}
            delay={400}
          />
          <Card
            img={sicon2}
            title="Social media content management"
            description={`Effortlessly create and post content that benefits your business. Our content creators and curators are highly experienced and know the ways to pull in relevant traffic and leads while maintaining a keen eye for branding.`}
            delay={600}
          />
          <Card
            img={sicon3}
            title="Mobile app ads"
            description={`Mobile ads can help you acquire fresh customers and boost your brand presence. Our ads specialists will help you pick the hot apps and prime spots.`}
            delay={800}
          />
          <Card
            img={sicon4}
            title="Facebook ads"
            description={`Online businesses are thriving through Facebook ads. Secure your ad spot quickly. Our Facebook ads strategies will help you outperform your rivals.`}
            delay={1000}
          />
          <Card
            img={sicon5}
            title="Instagram Ads"
            description={`
            Instagram is an excellent option to spread your brand and products like wildfire. Talk to our experts to scale up your reach massively.
            `}
            delay={1200}
          />
          <Card
            img={sicon6}
            title="Linkedin Ads"
            description={`Professionals and businesses look for professionals and businesses in Linkedin. Place your ads there to attract potential buyers and influencers.`}
            delay={1400}
          />
          <Card
            img={sicon7}
            title="Google Ads"
            description={`Google ads can take your brand and products to every single internet user quickly. Consult us to get the most sensible and money-saving Google Ads ideas.`}
            delay={1600}
          />
          <Card
            img={sicon8}
            title="Snapchat Ads"
            description={`Retail buyers spend their leisure time in Snapchat. We can help you with the statistics and techniques to place you Ads at the right spot to be profitable.`}
            delay={1800}
          />
          <Card
            img={sicon9}
            title="Twitter Ads"
            description={`Hashtags are one of the ideal ways to reach specific demography or target clients. Combine the power of Twitter ads and hashtags to boost your brand visibility and lead inflow.`}
            delay={2000}
          />
          <Card
            img={sicon10}
            title="Microsoft Advertisements"
            description={`Bid for Microsoft Ads and bump up your sales and branding. Our team can help you bid with the right price against millions of competitors to secure prime ad spots.`}
            delay={2200}
          />
        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}





export function ContentServices(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={c1}
            w={"450px"}
            title="General article writing"
            description={`You can post news articles and informative articles on high-traffic websites and blogs to improve your website traffic. Our writers know the market pulse like the back of their hands and can provide you with high-quality SEO-optimized articles`}
            delay={400}
          />
          <Card
            img={c2}
            title="Rewriting services"
            description={` If you are looking to reword existing articles, we are your best option. Our writers rephrase articles swiftly without spoiling the originality. Our rewriting services are cost-efficient and an ideal option if you want to fill your blog page quickly.`}
            delay={600}
          />
          <Card
            img={c3}
            title="Blog articles"
            description={`Blog pages can serve two purposes. First, blog articles can educate and motivate readers to opt for your products and services. Besides, blog posts that contain relevant keywords can bring in relevant traffic and potential buyers. We offer the best blog articles for your blog page.`}
            delay={800}
          />
          <Card
            img={c4}
            title="Copywriting"
            description={` Customers assess your expertise and professionalism by your website’s look and content. Make a grand impression by using copies that display your vision and reliability. Get the best copywriting services in Canada at a reasonable price point to boost your brand’s credibility.`}
            delay={1000}
          />
          <Card
            img={c5}
            title="Ghost-writing"
            description={`We offer the best ghost-writing services in Canada at an attractive price range. You can buy articles that provide insights, tips, and information regarding your products and services. Also, our writers can maintain anonymity, and you can use any name as the author for the articles you buy from us.`}
            delay={1200}
          />
          <Card
            img={c6}
            title="Press releases"
            description={`Press releases build a healthy relationship between your brand, media, investors, and all stakeholders. It also enables potential buyers and existing investors to stay updated regarding your brand’s advancements and prospects. Our expert writers will help you with clean and crisp press releases in the most optimal format.`}
            delay={1400}
            width="150px"
          />
          <Card
            img={cicon7}
            title="Product descriptions"
            description={` Buyers buy from experts, and product descriptions display your expertise. We can give you the best product descriptions that sell and rank well. Our SEO-optimized product descriptions can help you tackle competition and outrank top sellers while highlighting your product features to potential buyers.`}
            delay={1600}
            width={"150px"}
          />
          <Card
            img={dicon3}
            title="SEO optimization"
            description={` Search engine ranking is crucial for online businesses, and only those websites that secure the top few ranks get most of the online sales. We offer the best SEO optimization service in Canada. Our SEO team will help you identify the best ranking keywords and optimize your website content for better search engine ranking.`}
            delay={1800}
          />
          <Card
            img={c7}
            title="Website content"
            description={` SUNSAS Inc. has a team of highly professional content writers in Canada. Our writers are amiable and write unique website content that attracts users, improves SEO score, and boosts your brand credibility. Contact us now to discuss your content needs.`}
            delay={1800}
          />
          <Card
            img={c8}
            title="White papers"
            description={` Successful startups provide investors and customers with whitepapers that convey their plan of action to achieve their vision and mission. Our copywriters will help you prepare a crystal clear whitepaper that impresses all stakeholders, potential investors, and customers.`}
            delay={1800}
          />
          <Card
            img={c1}
            title="Bulk writing services"
            description={`We have a team of writers that can handle bulk writing works. Our writers create SEO-optimized unique content for all purposes. We never miss the timeline, and we always deliver the best quality content. Also, we offer special rates for regular and bulk orders.`}
            delay={1800}
          />
        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export function WebHostingService(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={web1}
            w={"1650px"}
            title="Launch a blazing website"
            description={`A business website can skyrocket your brand credibility and revenue quickly. Sunsas.INC will help you build the best website using Weebly or WordPress. We guarantee you a website that you and your target audience desire.`}
            delay={400}
          />
          <Card
            img={web2}
            title="Easy migration"
            description={`You got an existing business website that you feel might work better with a better web hosting company? Well, our experts can help you transfer your website to Sunsas.INC. WordPress migratory plugin can be an alternative for the same if you prefer.`}
            delay={600}
          />
          <Card
            img={web3}
            title="Exclusive E-commerce package"
            description={`With our E-commerce package, you can quickly build your online store on your preferred platforms like Woocommerce, Prestashop, and more. We offer end-to-end solutions for E-commerce requirements.`}
            delay={800}
          />
          <Card
            img={web4}
            w={"1650px"}
            title="Versatile hosting platform"
            description={`Our web-hosting platform supports multiple applications built on PHP, CRM, and MySQL-based CMS. We offer top ones like WordPress, Joomla, Drupal, and more for free if you prefer CMS.`}
            delay={1000}
          />


        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
export function WordpressWebHostingService(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={word1}
            w={"450px"}
            title="Express Launch"
            description={`Our WordPress installation process is mostly automated, and you’ll be amazed to see your functional website built efficiently in minutes. And, our WordPress web hosting services come with customizability features as well.`}
            delay={400}
          />
          <Card
            img={word2}
            title="Effortless WordPress Transfer"
            description={`Transferring your existing WordPress website to Sunsas>INC is pretty easy with WP migratory plugin. Also, our team of experts can help you do that from scratch professionally. In any case, you can transfer your WordPress website to us with ease.`}
            delay={600}
          />
          <Card
            img={word3}
            title="Powerful firewall & constant updates"
            description={`Our team keeps your website and WordPress applications updated with the latest plugins and patches. You can rest assured that your website will stay efficient and safe when you choose Sunsas.INC for web hosting.`}
            delay={800}
          />
          <Card
            img={word4}
            title="Ultimate Speed & performance"
            description={`Get the fastest loading time, Front-end optimization, Image optimization, and the best performance solutions at industry-best rates with Sunsas.INC. We offer free plugins that boost the overall performance, control, and user experience.`}
            delay={1000}
          />


        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}



export function WooCommerceHostingService(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={woo1}
            w={"450px"}
            title="Effortless Setup"
            description={`We offer you customizable stores with pre-installed themes and plugins. You can upload your products instantly and kickstart your WooCommerce sales in no time. Also, you can get our expert assistance in doing so.`}
            delay={400}
          />
          <Card
            img={woo2}
            title="Convenient payments system"
            description={`WooCommerce paired with our hosting services, lets you accept payments via bank transfers, cash, or PayPal. So, you can accept payments easily when you sell your products or services on WooCommerce.`}
            delay={600}
          />
          <Card
            img={woo3}
            title="Versatile E-commerce Platform"
            description={`WooCommerce offers the most versatile platform that allows you to create product bundles and pricing options of your choice. Be it a service or a product, and you can sell any product or services with ease with our WooCommerce hosting services.            `}
            delay={800}
          />
          <Card
            img={woo4}
            title="Customizable pricing options"
            description={`From the product price to the shipping fee, WooCommerce allows you to configure multiple options. Your customers can choose from various options based on the order quantity or product ordered. `}
            delay={1000}
          />
          <Card
            img={woo5}
            title="WooCommerce Plugins"
            description={`Our Full-service WooCommerce hosting services enable you to install plugins to enhance user experience and store functionality easily and efficiently.`}
            delay={1000}
          />
          <Card
            img={woo6}
            title="Convenient Dashboard"
            description={`WooCommerce dashboard lists the orders and their status for your convenience. So, you can efficiently serve your customers without missing the ETA.`}
            delay={1000}
          />


        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export function CloudHostingService(props) {
  return (
    <section className="service-block bg-gradient6 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="common-heading ptag">
              <span>Service</span>
              <h2>Our Services</h2>
              <p className="mb30">
                We think big and have hands in all leading technology platforms
                to provide you wide array of services.
              </p>
            </div>
          </div>
        </div>
        <div className="row upset link-hover">
          <Card
            img={cloud1}
            w={"450px"}
            title="Powerful & Ultrafast resources"
            description={`Our Full-service Cloud hosting services offer you the best cloud-based infrastructure that is dedicated, reliable, fast, and optimized for delivering the ultimate performance.`}
            delay={400}
          />
          <Card
            img={cloud2}
            title="Expert support"
            description={`Our team of experts takes care of the IT part of the cloud setup and optimization. Our team members are highly experienced and deliver the best results at all the times.`}
            delay={600}
          />
          <Card
            img={cloud3}
            title="Bigger & better scaling options"
            description={`Our cloud hosting services enable you to increase system power anytime you like. Besides, you can opt for our automatic scaling feature to keep your website functioning efficiently, even during sudden traffic surges.`}
            delay={800}
          />
          <Card
            img={cloud4}
            title="Convenient Cloud Account"
            description={`Sunsas.INC enables you to create custom hosting packages for your clients. You can allocate resources to websites individually as needed and allocate management tools according to your client’s needs.`}
            delay={1000}
          />

        </div>

        <div className="-cta-btn mt70">
          <div
            className="free-cta-title v-center wow zoomInDown"
            data-wow-delay="1.3s"
          >
            <p>
              Hire a <span>Dedicated Developer</span>
            </p>
            <Link to="/contact" className="btn-main bg-btn2 lnk">
              Hire Now
              <i className="fas fa-chevron-right fa-icon" />
              <span className="circle" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
