import React from 'react';
import research from '../../images/icons/research.svg';
import chat from '../../images/icons/chat.svg';
import monitor from '../../images/icons/monitor.svg';
import trasparency from '../../images/icons/trasparency.svg';

function Whychooseus(props) {
    return (
        <section className="why-choose pad-tb">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="common-heading">
                <span>We Are Awesome</span>
                <h2 className="mb30">Why Choose Us</h2>
              </div>
            </div>
          </div>
          <div className="row upset">
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon"><img src={research} alt="service" className="img-fluid" /></div>
                <h4>Reasearch and Analysis</h4>
                <p>A great idea isn’t enough to start a new business; it’s just the first step. You need solid research and detailed planning to be successful. Don’t invest your time and money into an idea without doing your research—plan before you act.</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon"><img src={chat} alt="service" className="img-fluid" /></div>
                <h4>Negotiation and power</h4>
                <p>the ability of the negotiator to influence the behavior of another. Commentators have observed a variety of aspects.</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon"><img src={monitor} alt="service" className="img-fluid" /></div>
                <h4>Creative and innovative solutions</h4>
                <p>Develop a creative mindset and tools that improve a team's ability to develop new solutions to complex challenges.</p>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 mt30">
              <div className="s-block up-hor">
                <div className="s-card-icon"><img src={trasparency} alt="service" className="img-fluid" /></div>
                <h4>Trasparency and ease of work</h4>
                <p>Obviously, better transparency in the workplace is not easy to obtain. ... obvious disconnect between the work being done and the company's</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default Whychooseus;