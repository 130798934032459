import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import {Digitalmarketing} from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About 
            title={"Digital Marketing Services"}
            about1={"Digital marketing is the largest business channel that can bring you NTB (new-to-book) clients and high-paying clients. We add prominence to your business strategy while equipping you with digital tools and an online presence. You may know things that can bring business to you, but we help you do it with ease using digital resources."}
            about2={"Digital marketing campaigns can help you reach out to potential customers. At the same time, it will enable customers to find you when they need your product or services. We have planned and executed numerous e-mail campaigns and social media advertising campaigns that delivered exceedingly awesome results."} 
            about3={"Click here to consult with us about your business."} 
            />
            <Tech />
            <Digitalmarketing />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;