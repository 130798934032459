import React from 'react';
import computers from '../../images/icons/computers.svg';
import worker from '../../images/icons/worker.svg';
import deal from '../../images/icons/deal.svg';
import aboutcompany from '../../images/about/about-company.jpg'
import whychoose from '../../images/wyc.png';
import {Link} from 'react-router-dom';

function WhyChooseUs(props) {
    return (
        <section className="why-choos-lg pad-tb deep-dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="common-heading text-l">
                <span>Why Choose Us</span>
                <h2 className="mb20">Why the <span className="text-second text-bold">SUNSAS INC</span> Among The Leading IT Companies</h2>
                <p>Sunsas provide various services, solutions and products across the globe.</p>
                <div className="itm-media-object mt40 tilt-3d">
                  <div className="media">
                    <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}><img src={computers} alt="icon" className="layer" /></div>
                    <div className="media-body">
                      <h4>Streamlined Project Management</h4>
                      <p>A clearer view of the business through the capability to monitor all projects within a single solution. Improved time and cost efficiency.</p>
                    </div>
                  </div>
                  <div className="media mt40">
                    <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}><img src={worker} alt="icon" className="layer" /></div>
                    <div className="media-body">
                      <h4>A Qualified Team of Experts</h4>
                      <p>Our team of qualified experts deliver proactive services and professional advise to support your business plan.</p>
                    </div>
                  </div>
                  <div className="media mt40">
                    <div className="img-ab- base" data-tilt data-tilt-max={20} data-tilt-speed={1000}> <img src={deal} alt="icon" className="layer" /></div>
                    <div className="media-body">
                      <h4>Completion of Project in Given Time</h4>
                      <p>Project needs to end and that's what project completion is all about in the to give the project a priority so that the project can be completed on time.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div data-tilt data-tilt-max={5} data-tilt-speed={1000} className="single-image bg-shape-dez wow fadeIn" data-wow-duration="2s"><img src={whychoose} alt="image" className="img-fluid" /></div>
              {/* <p className="text-center mt30">Currently we Design 1100+ websites and Mobile Apps on our Team. Its growing every day.</p> */}
              <div className="cta-card mt60 text-center">
                <h3 className="mb20">Let's Start a  <span className="text-second text-bold">New Project</span> Together</h3>
                <p>We have successfully reached 10+ countries with complete clients Satisfaction</p>
                <Link to="/contact" className="btn-outline lnk mt30">Request A Quote    <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default WhyChooseUs;