import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import {ContentServices} from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function ContentWriting(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About 
            title={"Content Writing Services"}
            about1={'Content is the king, but only successful brands know that the content is the king-maker. A well-built website paired with optimal content is like an autopilot. It will keep generating traffic, leads, and sales even when you are away and busy with other things. '} 
            about2={"Even a startup can start competing with top online brands when the website content is compelling. We at SUNSAS Inc. offer the best content writing services in Canada. We know the importance of website content and blog articles, and we always hit the mark."}
            about3={"Our copywriters and content writers have years of experience creating content that sells. Get the SEO-optimized content for your websites and blogs to improve search engine ranking. Let your customers find you easily by optimizing your website and blog content."} />
            <Tech />
            <ContentServices />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default ContentWriting;