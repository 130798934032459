import React, { useEffect } from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import { WebHostingService } from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';



function Serviceindex(props) {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About
                title={"Full-range Web Hosting Services"}
                about1={'We offer faster, safer, and dependable web hosting services to startups, small and big enterprises. Sunsas.INC full-range web hosting services come with exclusive solutions for ultimate performance.'}
                about2={""}
                about3={"Click here to know more."} />
            <Tech />
            <WebHostingService />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;