import React, {useEffect} from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import {Socialmedia} from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About 
            title={"Social Media Services"}
            about1={"SUNSAS Inc. offers the best social media services in Canada. Social media is where you can reach out to millions of customers in a short span. Social media users are tech-savy and believe in choosing brands with the highest social media presence and online presence. We know exactly how to grab the attention of social media users."}
            about2={"Social media ads can be expensive if you don’t know how to bid. Our social media ads services will help you get the most out of your advertisement budget. We have an innovative and creative team that can help you with the high-yielding social media ad strategies."}
            about3={"We optimize your social media content to increase the views and clicks. Also, our hastag research will help you identify the high-traffic hastags that can take your products and services to the most relevant customers."} />
            <Tech />
            <Socialmedia />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;