import React from 'react';

function About(props) {
    return (
        <section className="about-sec-rpb pad-tb">
  <div className="container">
    <div className="row justify-content-center text-center">
      <div className="col-lg-10">
        <div className="common-heading">
          <span>We Are Creative Agency</span>
          <h1 className="mb30"><span className="text-second">Top-rated</span> End to End IT service provider Company</h1>
         
          <p>We provide  <span className="text-radius text-light text-animation bg-b"> Mobile App Development , website development   </span>
          Mobile App Development, Digital Marketing services. Sunsas is one of the fastest growing IT company in Canada. Our company head office located in Ontario, Canada. We have successfully delivered projects across the world. We provide our customers with really affordable web Design plans at the highest quality possible.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    );
}

export default About;