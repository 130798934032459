import React from 'react';
import img1 from '../../images/web.gif'


function About(props) {
    return (
        <section className="service pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="image-block upset bg-shape wow fadeIn">
                <img src={img1} alt="image" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-8 block-1">
              <div className="common-heading text-l pl25">
                <span>Overview</span>
                <h2>Our {props.title}</h2>
                <p>{props.about1} </p>
                <p>{props.about2} </p>
                <p>{props.about3} </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default About;