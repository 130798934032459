import React from "react";
import triprint from "../../images/triprint.png";
import townwings from "../../images/townwings.png";
import vastrissilks from "../../images/vastrissilks.png";

import { Fade } from "react-reveal";

const Card = (props) => (
  <li
    className="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn"
    data-wow-delay=".2s"
  >
    <Fade delay={props.delay}>
    <div className="brand-logo">
      <img src={props.img} alt="clients" className="img-fluid" />
    </div>
    <p>{props.text}</p>
    </Fade>
  </li>
);

function Clients(props) {
  return (
    <section className="clients-section- bg-gradient15 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading">
              <span>Our happy customers</span>
              <h2 className="mb30">Some of our Clients</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="clients-logos text-center col-12">
              <ul className="row text-center clearfix">
                
                  <Card img={triprint} text="Ontario, Canada" delay={400} />
              
             
                  <Card img={townwings} text="Ontario, Canada" delay={600} />
               

                <Card img={vastrissilks} text="Ontario, Canada" delay={800} />
             
               
                <Card img={triprint} text="Ontario, Canada" delay={1000}/>
                
                
                {/* <Card img={vast} text="QTP, Australia" delay={1200} />
                
                <Card img={logo2} text="PAPA, Malaysia" delay={1400}/> */}
               
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
