import React from 'react';
import icon1 from '../../images/icons/stack-icon1.png';
import icon2 from '../../images/icons/stack-icon2.png';
import icon3 from '../../images/icons/stack-icon3.png';
import icon4 from '../../images/icons/stack-icon4.png';
import icon5 from '../../images/icons/stack-icon5.png';
import icon6 from '../../images/icons/stack-icon6.png';
import icon7 from '../../images/icons/stack-icon7.png';
import icon8 from '../../images/icons/stack-icon8.png';

function Tech(props) {
    return (
        <div className="techonology-used-">
        <div className="container">
          <ul className="h-scroll tech-icons">
            <li><a href="#"><img src={icon1} alt="icon" /></a></li>
            <li><a href="#"><img src={icon2} alt="icon" /></a></li>
            <li><a href="#"><img src={icon3} alt="icon" /></a></li>
            <li><a href="#"><img src={icon4} alt="icon" /></a></li>
            <li><a href="#"><img src={icon5} alt="icon" /></a></li>
            <li><a href="#"><img src={icon6} alt="icon" /></a></li>
            <li><a href="#"><img src={icon7} alt="icon" /></a></li>
            <li><a href="#"><img src={icon8} alt="icon" /></a></li>
          </ul>
        </div>
      </div>
      
    );
}

export default Tech;