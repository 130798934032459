import React, {useEffect} from 'react';
import Header from '../header/header';
import Breadcrumb from './Breadcrumb';
import BlogCard from './BlogCard';
import Footer from '../Footer/footer';

function Blogindex(props) {
    useEffect(()=>{
        window.scroll(0,0);
    },[])
    return (
        <div>
            <Header />
            <Breadcrumb />
            <BlogCard />
            <Footer />
        </div>
    );
}

export default Blogindex;