import React from 'react';
import icon1 from '../../images/icons/icon-1.png';
import icon2 from '../../images/icons/icon-2.png';
import icon3 from '../../images/icons/icon-3.png';
import icon4 from '../../images/icons/icon-4.png';
import icon5 from '../../images/icons/icon-5.png';
import icon6 from '../../images/icons/icon-6.png';
import icon7 from '../../images/icons/icon-7.png';
import icon8 from '../../images/icons/icon-8.png';
import icon9 from '../../images/icons/icon-9.png';
import icon10 from '../../images/icons/icon-10.png';
import icon11 from '../../images/icons/icon-11.png';
import icon12 from '../../images/icons/icon-12.png';
import {Fade} from "react-reveal";


function Category(props) {
    return (
        <section className="work-category pad-tb">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 v-center">
          
              <div className="common-heading text-l">
                <span>Industries we work for</span>
                <h2>Helping Businesses in All Domains</h2>
                <p>We do PHP Development, Ecommerce Development, software development, Web development, Custom Application,etc...</p>
              </div>
             
            </div>
            <div className="col-lg-8">
          
              <div className="work-card-set">


                <Fade delay={400} >
                <div className="icon-set wow fadeIn" data-wow-delay=".2s">
                  <div className="work-card cd1">
                    <div className="icon-bg"><img src={icon1} alt="Industries" /></div>
                    <p>Social Networking</p>
                  </div>
                </div>
                </Fade>
                <Fade delay={600} >
                <div className="icon-set wow fadeIn" data-wow-delay=".4s">
                  <div className="work-card cd2">
                    <div className="icon-bg"><img src={icon2} alt="Industries" /></div>
                    <p>Digital Marketing</p>
                  </div>
                </div>
                </Fade>
                <Fade delay={800} >
                <div className="icon-set wow fadeIn" data-wow-delay=".6s">
                  <div className="work-card cd3">
                    <div className="icon-bg"><img src={icon3} alt="Industries" /></div>
                    <p>Ecommerce Development</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={800} >
                <div className="icon-set wow fadeIn" data-wow-delay=".8s">
                  <div className="work-card cd4">
                    <div className="icon-bg"><img src={icon4} alt="Industries" /></div>
                    <p>Video Service</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={1000} >
                <div className="icon-set wow fadeIn" data-wow-delay="1s">
                  <div className="work-card cd5">
                    <div className="icon-bg"><img src={icon5} alt="Industries" /></div>
                    <p>Small Business</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={1200} >
                <div className="icon-set wow fadeIn" data-wow-delay="1.2s">
                  <div className="work-card cd6">
                    <div className="icon-bg"><img src={icon6} alt="Industries" /></div>
                    <p>Enterprise Service</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={1400} >
                <div className="icon-set wow fadeIn" data-wow-delay="1.4s">
                  <div className="work-card cd7">
                    <div className="icon-bg"><img src={icon7} alt="Industries" /></div>
                    <p>Education Service</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={1400} >
                <div className="icon-set wow fadeIn" data-wow-delay="1.6s">
                  <div className="work-card cd8">
                    <div className="icon-bg"><img src={icon8} alt="Industries" /></div>
                    <p>Tour and Travels</p>
                  </div>
                </div>
                </Fade>
                <Fade delay={1600} >
                <div className="icon-set wow fadeIn" data-wow-delay="1.8s">
                  <div className="work-card cd9">
                    <div className="icon-bg"><img src={icon9} alt="Industries" /></div>
                    <p>Health Service</p>
                  </div>
                </div>
                </Fade>


                <Fade delay={1800} >
                <div className="icon-set wow fadeIn" data-wow-delay="2s">
                  <div className="work-card cd10">
                    <div className="icon-bg"><img src={icon10} alt="Industries" /></div>
                    <p>Event &amp; Ticket</p>
                  </div>
                </div>
                </Fade>

                <Fade delay={2000} >
                <div className="icon-set wow fadeIn" data-wow-delay="2.2s">
                  <div className="work-card cd11">
                    <div className="icon-bg"><img src={icon11} alt="Industries" /></div>
                    <p>Restaurant Service</p>
                  </div>
                </div>
                </Fade>
                <Fade delay={2200} >
                <div className="icon-set wow fadeIn" data-wow-delay="2.4s">
                  <div className="work-card cd12">
                    <div className="icon-bg"><img src={icon12} alt="Industries" /></div>
                    <p>Business Consultant</p>
                  </div>
                </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );
}

export default Category;