import React, { useState } from "react";
import { Drawer, List, ListItem, Button } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';

function Sidemenu(props) {
  return (
    <Drawer
      className="drawer"
      open={props.open}
      onClose={(e) => props.close(false)}
    >
      <List className="List">
        <ListItem className="Top-nav-list">
          <h4 className="listbutton list-head">MENU</h4>
        </ListItem>

        <ListItem>
          <Link to="/" >
            <Accordion className="accord" >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="listbutton" >
                  Home
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Link>
        </ListItem>



        <ListItem>
          <Link to="/about" >
            <Accordion className="accord" >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="listbutton" >
                  About
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Link>
        </ListItem>



        <ListItem>
          {/* <Button className="listbutton">Service</Button> */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="listbutton" >Services</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Link to="consultationservice" > <Button className="listbutton" >Consultation </Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="DigitalMarketing" > <Button className="listbutton" >Digital Marketing</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="Socialmedia" > <Button className="listbutton" >Social Media</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="OurAppProcess" > <Button className="listbutton" >Our App Process</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="ContentWriting" > <Button className="listbutton" >Content Writing</Button> </Link>
            </AccordionDetails>

            <AccordionDetails>
              <Link to="WebHostingService" > <Button className="listbutton" >Full-range Web Hosting Services</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="WordpressWebHostingService" > <Button className="listbutton" >Full-service WordPress Web Hosting</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="wooCommerceHosting" > <Button className="listbutton" >Full-service WooCommerce Hosting</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="CloudHostingService" > <Button className="listbutton" >Cloud Hosting Services</Button> </Link>
            </AccordionDetails>
          </Accordion>
        </ListItem>



        <ListItem>
          {/* <Button className="listbutton">Service</Button> */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="listbutton" >Hosting</Typography>
            </AccordionSummary>
                       
            <AccordionDetails>
              <Link to="CloudHostingService" > <Button className="listbutton" >AWS Cloud Services</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="CloudHostingService" > <Button className="listbutton" >Azure Cloud Services</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="CloudHostingService" > <Button className="listbutton" >Google Cloud Services</Button> </Link>
            </AccordionDetails>

            <AccordionDetails>
              <Link to="WebHostingService" > <Button className="listbutton" >Full-range Web Hosting Services</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="WordpressWebHostingService" > <Button className="listbutton" >Full-service WordPress Web Hosting</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="wooCommerceHosting" > <Button className="listbutton" >Full-service WooCommerce Hosting</Button> </Link>
            </AccordionDetails>
            <AccordionDetails>
              <Link to="CloudHostingService" > <Button className="listbutton" >Cloud Hosting Services</Button> </Link>
            </AccordionDetails>
          </Accordion>
        </ListItem>



        <ListItem>
          <Link to="/portfolio" >
            <Accordion className="accord" >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="listbutton" >
                  Portfolio
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Link>
        </ListItem>


        <ListItem>
          <Link to="/blog" >
            <Accordion className="accord" >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="listbutton" >
                  Blog
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Link>
        </ListItem>


        <ListItem>
          <Link to="/contact" >
            <Accordion className="accord" >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="listbutton" >
                  Contact
                </Typography>
              </AccordionSummary>
            </Accordion>
          </Link>
        </ListItem>




      </List>
    </Drawer>
  );
}

export default Sidemenu;
