import React, { useEffect } from 'react';
import Header from '../header/header';
import BreadCrumbs from './breadcrumb';
import Client from './Client';
import About from './About';
import Tech from './Tech';
import { WordpressWebHostingService } from './ourservice';
import Process from './Process';
// import Whychooseus from './whychooseus';
import Projects from './Projects';
import CTA from '../home/CTA';
import Footer from '../Footer/footer';

function Serviceindex(props) {
    useEffect(() => {
        window.scroll(0, 0);
    }, [])
    return (
        <div>
            <Header color="w" />
            {/* <BreadCrumbs /> */}
            {/* <Client /> */}
            <About
                title={"Full-service WordPress Web Hosting"}
                about1={'Our Full-service WordPress Web Hosting services include ongoing management, updates, security, speed optimization, performance-boosting, and versatile platform for your web applications. '}
                about2={""}
                about3={"Click here to know more."} />
            <Tech />
            <WordpressWebHostingService />
            {/* <Whychooseus /> */}
            {/* <Projects /> */}
            <CTA />
            <Footer />
        </div>
    );
}

export default Serviceindex;