import React, {useEffect} from 'react';
import Header from '../header/header';
import Footer from '../Footer/footer';
import Breadcrumbs from './breadcrumbs';
import Portfolio from './portfoliocard';

function PortfolioIndex(props) {
    useEffect(()=> {
        window.scroll(0,0)
    },[])

    return (
        <div>
            <Header />
            <Breadcrumbs />
            <Portfolio />
            <Footer />
        </div>
    );
}

export default PortfolioIndex;