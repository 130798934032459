import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Menu from './Sidemenu';
import MenuIcon from '@material-ui/icons/Menu';
import Sunsaslogo from '../../images/Sunsaslogo.png';
import sunsaswhitelogo from '../../images/sunsaswhitelogo.png';


function Header(props) {
  const [offset, setOffset] = useState(0)
  const [opendrawer, setopendrawer] = useState(false);


  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    }
  }, [])
  return (
    <header className={`nav-bg-${props.color} ${offset > 50 ? 'sticky' : ''} main-header navfix fixed-top menu-white`}>
      <div className="container-fluid m-pad">
        <div className="menu-header">
          <div className="dsk-logo"><a className="nav-brand" href="/">
            {
              window.location.pathname === "/" ? (
                offset > 50 ? <img src={Sunsaslogo} alt="Sunsaslogo" /> :
                  (
                    <img src={sunsaswhitelogo} alt="sunsaswhitelogo" />
                  )
              ) :
                (
                  <img src={Sunsaslogo} alt="Sunsaslogo" />
                )
            }

          </a></div>
          <div className="custom-nav" role="navigation">
            <ul className="nav-list">
              <li className="sbmenu"><Link to="/" className="menu-links">Home</Link></li>
              <li className="sbmenu"><Link to="/about" className="menu-links">About Us</Link></li>
              <li className="sbmenu rpdropdown"><a href="#" className="menu-links">Services</a>
                <div className="nx-dropdown menu-dorpdown">
                  <div className="sub-menu-section"><div className="sub-menu-center-block">
                    <div className="sub-menu-column smfull">
                      <ul>
                        <li><Link to="consultationservice">Consultation Services</Link> </li>
                        <li><Link to="DigitalMarketing"> Digital Marketing Services  </Link> </li>
                        <li><Link to="Socialmedia">Social Media Services</Link> </li>
                        <li><Link to="ContentWriting">Content Writing Services</Link> </li>
                        <li><Link to="OurAppProcess">Our App Process</Link> </li>
                       
                      </ul>
                    </div>
                  </div>
                  </div>
                </div>
              </li>

              <li className="sbmenu rpdropdown"><a href="#" className="menu-links">Hosting</a>
                <div className="nx-dropdown menu-dorpdown">
                  <div className="sub-menu-section"><div className="sub-menu-center-block">
                    <div className="sub-menu-column smfull">
                      <ul>
                        <li><Link to="CloudHostingService">AWS Cloud Services</Link> </li>
                        <li><Link to="CloudHostingService">Azure Cloud Services</Link> </li>
                        <li><Link to="CloudHostingService">Google Cloud Services</Link> </li>
                        <li><Link to="WebHostingService">Full-range Web Hosting Services</Link> </li>
                        <li><Link to="WordpressWebHostingService">Full-service WordPress Web Hosting</Link> </li>
                        <li><Link to="wooCommerceHosting">Full-service WooCommerce Hosting</Link> </li>
                        <li><Link to="CloudHostingService">Cloud Hosting Services</Link> </li>
                      </ul>
                    </div>
                  </div>
                  </div>
                </div>
              </li>

              <li className="sbmenu"><Link to="/Portfolio" className="menu-links">Portfolio</Link></li>
              {/* <li className="sbmenu"><Link to="/blog" className="menu-links">Blog</Link></li> */}
              <li className="sbmenu"><Link to="/contact" className="menu-links">Contact</Link></li>

              <li><a href="#" className="menu-links right-bddr">&nbsp;</a>
                {/*menu right border*/}
              </li><li className="contact-show"><a href="#" className="btn-round- trngl btn-br bg-btn"><i className="fas fa-phone-alt" /></a>
                <div className="contact-inquiry">
                  <div className="contact-info-">
                    <div className="contct-heading">SUNSAS Contacts</div>
                    <div className="inquiry-card-nn hrbg">
                    </div>
                    <div className="inquiry-card-nn">

                      <ul>
                        <li><i className="fab fa-skype" /><a href="#">sunsas.ca</a></li>
                        <li><i className="fas fa-envelope" /><a href="mailto:info@sunsas.ca">info@sunsas.ca</a></li></ul>
                    </div>
                  </div>
                </div>
              </li>
              <li><Link to="/contact" className="btn-br bg-btn3 btshad-b2 lnk">Request A Quote <span className="circle" /></Link> </li>

            </ul>
          </div>





          <div className="mobile-menu2">
            <ul className="mob-nav2">
              <li><a href="#" className="btn-round- trngl btn-br bg-btn btshad-b1" data-toggle="modal" data-target="#menu-popup"><i className="fas fa-envelope-open-text" /></a></li>

              <li><MenuIcon style={{
                fontSize: "50px",
                cursor: "pointer",
                color: 'black'
              }} onClick={() => setopendrawer(true)} /></li>
            </ul>
          </div>
        </div>
        <Menu open={opendrawer} close={e => setopendrawer(e)} />


      </div>
      <div className="popup-modal1">
        <div className="modal" id="menu-popup">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="common-heading">
                  <h4 className="mt0 mb0">Write a Message</h4>
                </div>
                <button type="button" className="closes" data-dismiss="modal">×</button></div>
              {/* Modal body */}
              <div className="modal-body">
                <div className="form-block fdgn2 mt10 mb10">
                  <form action="#" method="post" name="feedback-form">
                    <div className="fieldsets row">
                      <div className="col-md-12"><input type="text" placeholder="Full Name" name="name" /></div>
                      <div className="col-md-12"><input type="email" placeholder="Email Address" name="email" /></div>
                      <div className="col-md-12"><input type="number" placeholder="Contact Number" name="phone" /></div>
                      <div className="col-md-12"><input type="text" placeholder="Subject" name="subject" /></div>
                      <div className="col-md-12"><textarea placeholder="Message" name="message" defaultValue={""} /></div>
                    </div>
                    <div className="fieldsets mt20 pb20">
                      <button type="submit" name="submit" className="lnk btn-main bg-btn" data-dismiss="modal">Submit <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </header>

  );
}

export default Header;





