import React ,{useState}from 'react';
import deal from '../../images/icons/deal.svg';
import computers from '../../images/icons/computers.svg';
import worker from '../../images/icons/worker.svg';
import customerservice from '../../images/icons/customer-service.svg';
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";




const Counter = ({ className, ...rest })=> {
  const [viewPortEntered, setViewPortEntered] = useState(false);
  return(
    <CountUp {...rest} className="counter" duration={5} start={viewPortEntered ? null : 0}>
    {({ countUpRef }) => {
      return (
        <VisibilitySensor
          active={!viewPortEntered}
          onChange={isVisible => {
            if (isVisible) {
              setViewPortEntered(true);
            }
          }}
          delayedCall
        >
          <h4 className={className} ref={countUpRef} />
        </VisibilitySensor>
      );
    }}
  </CountUp>
  )
}




function Client(props) {
    return (
        <div className="statistics-wrap bg-gradient5">
        <div className="container">
          <div className="row small t-ctr mt0">
            <div className="col-lg-3 col-sm-6">
              <div className="statistics">
                <div className="statistics-img">
                  <img src={deal} alt="happy" className="img-fluid" />
                </div>
                <div className="statnumb">
                <Counter  end={50}  />
                  <p>Happy Clients</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="statistics">
                <div className="statistics-img">
                  <img src={computers} alt="project" className="img-fluid" />
                </div>
                <div className="statnumb counter-number">
                <Counter  end={60} suffix="+" />
                  <p>Projects Done</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="statistics">
                <div className="statistics-img">
                  <img src={worker} alt="work" className="img-fluid" />
                </div>
                <div className="statnumb">
                <Counter  end={95} suffix="K" />
                  <p>Hours Worked</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="statistics mb0">
                <div className="statistics-img">
                  <img src={customerservice} alt="support" className="img-fluid" />
                </div>
                <div className="statnumb">
                <Counter  end={24} suffix="/7" />
                             <p>Support Available</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    );
}

export default Client;