import React from 'react';
import blog1 from '../../images/blog1.png';
import blog2 from '../../images/blog2.png';
import Sunsaslogo from '../../images/Sunsaslogo.png';
import {Link} from 'react-router-dom';

function Footer(props) {
    return (
        <footer>
  <div className="footer-row1">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="email-subs">
            <h3>Get New Insights Weekly</h3>
            <p> Enter your email</p>
          </div>
        </div>
        <div className="col-lg-6 v-center">
          <div className="email-subs-form">
            <form>
              <input type="email" placeholder="Email Your Address" name="emails" />
              <button type="submit" name="submit" className="lnk btn-main bg-btn">Subscribe <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-row2">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-3 col-sm-6  ftr-brand-pp">
          <a className="navbar-brand mt30 mb25" href="#"> <img src={Sunsaslogo} alt="Logo" width={100} /></a>
          <p>Sunsas is a company founded as the provider of reliable and quality IT Services</p>
          <Link to="/contact" className="btn-main bg-btn3 lnk mt20">Become Partner <i className="fas fa-chevron-right fa-icon" /><span className="circle" /></Link>
        </div>
        <div className="col-lg-3 col-sm-6">
          <h5>Contact Us</h5>
          <ul className="footer-address-list ftr-details">
            <li>
              <span><i className="fas fa-envelope" /></span>
              <p>Email <span> <a href="mailto:info@sunsas.ca">info@sunsas.ca</a></span></p>
            </li>
            <li>
              <span><i className="fas fa-phone-alt" /></span>
              <p>Phone <span> <a href="tel:+16473257275">+1 647 325-7275</a></span></p>
            </li>
            <li>
              <span><i className="fas fa-map-marker-alt" /></span>
              <p>Address <span> 107 Eastvale Dr, Markham, ON L3S 4T4, Canada</span></p>
            </li>
          </ul>
        </div>
        <div className="col-lg-2 col-sm-6">
          <h5>Company</h5>
          <ul className="footer-address-list link-hover">
            <li><Link to="/contact" >Contact</Link></li>
            <li><a href="javascript:void(0)">Customer's FAQ</a></li>
            <li><a href="javascript:void(0)">Refund Policy</a></li>
            <li><a href="javascript:void(0)">Privacy Policy</a></li>
            <li><a href="javascript:void(0)">Terms and Conditions</a></li>
            <li><a href="javascript:void(0)">License &amp; Copyright</a></li>
          </ul>
        </div>
        <div className="col-lg-4 col-sm-6 footer-blog-">
          <h5>Latest Blogs</h5>
          <div className="single-blog-">
            <div className="post-thumb"><a href="#"><img src={blog1} alt="blog" /></a></div>
            <div className="content">
              <p className="post-meta"><span className="post-date"><i className="far fa-clock" />April 15, 2020</span></p>
              <h4 className="title"><a href="#">We Provide you Best &amp; Creative Consulting Service</a></h4>
            </div>
          </div>
          <div className="single-blog-">
            <div className="post-thumb"><a href="#"><img src={blog2} alt="blog" /></a></div>
            <div className="content">
              <p className="post-meta"><span className="post-date"><i className="far fa-clock" />April 15, 2020</span></p>
              <h4 className="title"><a href="#">We Provide you Best &amp; Creative Consulting Service</a></h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div className="footer-row3">
    <div className="copyright">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            {/* <div className="footer-social-media-icons">
              <a href="javascript:void(0)" target="blank"><i className="fab fa-facebook" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-twitter" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-instagram" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-linkedin" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-youtube" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-pinterest-p" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-vimeo-v" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-dribbble" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-behance" /></a>
            </div> */}
            <div className="footer-">
              <p>Copyright © 2021 SUNSAS INC. All rights reserved. Developed by <a href="https://sunsas.ca/" target="blank">SUNSAS</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

    );
}

export default Footer;