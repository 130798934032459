import React from "react";


const Card = ()=>(
    <div className="col-lg-4 mt60">
  <div className="single-blog-post- shdo">
    <div className="single-blog-img-">
      <a href="#"><img src="images/blog/blog-dg-1.jpg" alt="girl" className="img-fluid" /></a>
      <div className="entry-blog-post dg-bg2">
        <span className="bypost-"><a href="#"><i className="fas fa-tag" /> Nodejs</a></span>
        <span className="posted-on-">
          <a href="#"><i className="fas fa-clock" /> Sep 23, 2020</a>
        </span>
      </div>
    </div>
    <div className="blog-content-tt">
      <div className="single-blog-info-">
        <h4><a href="#">Everything You Need To Know About Nodejs!</a></h4>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      </div>
      <div className="post-social">
        <div className="ss-inline-share-wrapper ss-hover-animation-fade ss-inline-total-counter-left ss-left-inline-content ss-small-icons ss-with-spacing ss-circle-icons ss-without-labels">
          <div className="ss-inline-share-content">
            <div className="ss-social-icons-container">
              <a href="javascript:void(0)">Shares</a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-facebook" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-twitter" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-linkedin" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fas fa-envelope" /></a>
              <a href="javascript:void(0)" target="blank"><i className="fab fa-facebook-messenger" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

)



function BlogCard(props) {
  return (
    <section className="blog-page pad-tb pt40">
      <div className="container">
        <div className="row">
            <Card />
            <Card />
            <Card />

        </div>
      </div>
    </section>
  );
}

export default BlogCard;
