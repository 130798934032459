import React , {useState} from "react";
import { Fade, Slide } from "react-reveal";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {Link} from 'react-router-dom';
import carrental from '../../images/carrental.png';
import VR from '../../images/VR.png';
import startup from '../../images/startup.png';
import ModalVideo from 'react-modal-video';
import "../../css/reactmodal.css";

function TopSection(props) {
  const [isOpen, setOpen] = useState(false)

  return (
    <section className="hero-card-web bg-gradient12 shape-bg3">
    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="L61p2uyiMSo" onClose={() => setOpen(false)} />

      <div className="hero-main-rp container-fluid">
        <div className="row">
          <div className="col-lg-5">
            <div className="hero-heading-sec">
              <Fade duration={1000} delay={500}>
                <h2>
                  <span>CREATIVE</span> <span>WEBSITE & APP</span> <span>DESIGN</span>{" "}
                  <span>COMPANY.</span>
                </h2>
              </Fade>

              <Fade duration={1000} delay={900}>
                <p className="animate__animated animate__fadeIn animate__delay-900ms">
                  Website and App development solution for transforming and
                  innovating businesses.
                </p>
              </Fade>

              <Fade duration={1000} delay={1200}>
                <Link to="/contact" className="btn-main bg-btn lnk ">
                  Request a Quote{""}
                  <i className="fas fa-chevron-right fa-ani" />
                  <span className="circle" />
                </Link>
              </Fade>

            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-content-sec animate__animated animate__fadeIn animate__delay-1.9s">
              {/* <div className="video-intro-pp">
                <a
                  className="video-link play-video"
                  href="#"
                  onClick={()=> setOpen(true) }
                >
                  <span className="triangle-play" />
                </a>
              </div> */}

              <div className="title-hero-oth">
                <p>
                  We design digital solutions{" "}
                  <span>for brands and companies</span>
                </p>
              </div>
            </div>
            <Slide right delay={400} duration={2300}  >
            <OwlCarousel
              items="3"
              autoplay={true}
              dots={false}
              margin={20}
              autoplayTimeout={3500}
              autoplayHoverPause={true}
              smartSpeed={2000}
              loop={true}
              responsive={{
                0: {
                  items: 1,
                },
                520: {
                  items: 2,
                },
                768: {
                  items: 3,
                },
                1200: {
                  items: 3,
                },
                1400: {
                  items: 3,
                },
                1600: {
                  items: 3,
                },
              }}
            >
              <div class="item">
                <div className="service-slide card-bg-a">
                  <a href="#">
                    <div className="service-card-hh">
                      <div className="image-sr-mm">
                        <img alt="custom-sport" src={VR} />
                      </div>
                      <div className="title-serv-c">
                        <span>VR</span> Solution
                      </div>
                    </div>
                  </a>
                </div>
              </div>

              <div class="item">
                <div className="service-slide card-bg-b">
                  <a href="#">
                    <div className="service-card-hh">
                      <div className="image-sr-mm">
                        <img
                          alt="custom-sport"
                          src={VR}
                        />
                      </div>
                      <div className="title-serv-c">
                        <span>Custom</span> App Solution
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item">
                <div
                  className="service-slide card-bg-c"
                >
                  <a href="#">
                    <div className="service-card-hh">
                      <div className="image-sr-mm">
                        <img
                          alt="custom-sport"
                          src={startup} 
                        />
                      </div>
                      <div className="title-serv-c">
                        <span>Startup</span> Solution
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div class="item">
                <div
                  className="service-slide card-bg-d"
                >
                  <a href="#">
                    <div className="service-card-hh">
                      <div className="image-sr-mm">
                        <img
                          alt="custom-sport"
                          src={carrental} 
                        />
                      </div>
                      <div className="title-serv-c">
                        <span>Car</span> Rental Solution
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </OwlCarousel>
            </Slide>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TopSection;
